<template>
  <div class="app-wrap">
    <div id="nav">
      <NavBarSetting />
    </div>
    <div class="main">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    NavBarSetting: () => import('./NavBarSetting.vue')
  }
};
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.app-wrap {
  position: relative;
  padding: 16px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background-color: #eeeeee;
  #nav::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  #nav::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
  }
  #nav::-webkit-scrollbar-thumb {
    background: #704ad1;
    border-radius: 3px;
  }
  #nav {
    position: absolute;
    width: 267px;
    height: 94%;
    padding: 0px;
    padding-left: 5px;
    overflow-y: auto;
    margin-top: 4px;
    cursor: pointer;
  }
  .main {
    // width : 100% ;
    // width: 81%;
    display: block;
    padding: 10px;
    height: 100%;
    margin-left: 266px;
    margin-right: -16px;
    overflow-y: hidden;
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    // border-radius: 0 20px 20px 20px;
    box-sizing: border-box;
    z-index: 7;
    box-shadow: -5px 5px 10px #00000017;
    margin-top: 3px;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 768px) {
  .app-wrap {
    .header {
      .icon {
        background-color: transparent;
        float: left;
        display: block;
        border: none;
        height: 35px;
        width: 35px;
        color: #2dabe2;
        border-radius: 5px;
        outline: 0;
        &:hover {
          border-radius: 50%;
          background-color: rgba(219, 217, 217, 0.54);
        }
      }
    }
  }
}
</style>
